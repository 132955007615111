import React from "react";
import { experienceData } from "../../../data/experience";
import Seperator from "../../common/seperator/Seperator";
import "./experience.css";
import "./experienceCard.css";
import ExperienceCard from "./ExperienceCard";

function Experience() {
  const data = experienceData;
  const Four07index = 3;
  return (
    <div className="work">
      <Seperator />
      <div className="section-title" style={{ color: "white" }}>
        Experience
      </div>
      <div className="work-list">
        <div>
          {data.map((item, i) => {
            if (i < Four07index) {
              return <ExperienceCard item={item} />;
            }
          })}
          <div className="work-card">
            <img
              src={experienceData[Four07index].logo}
              className="work-logo"
              alt=""
            />
            <div className="work-info">
              <div className="company-name">
                {experienceData[Four07index].company}
              </div>
              <br />
              <div className="company-job">
                <strong>{experienceData[Four07index].designation}</strong>
              </div>
              <div className="work-dates">
                {experienceData[Four07index].dateJoining} -{" "}
                {experienceData[Four07index].dateEnd}
              </div>
            </div>
          </div>
          <strong style={{ color: "white" }}>
            <u>Team 1: Data Engineering Team (May 2021 - Dec 2021)</u>
          </strong>
          <div style={{ color: "white" }}>
            <strong>Stack: </strong>
            SQL, Python, AWS Lambda, AWS S3, Amazon DynamoDB, SAP, Eclipse, Git
          </div>
          <ul className="tasks-list">
            <li>
              Developed REST APIs in SAP HANA with OData API services, enabling
              streamlined automation for creating reports in the AWS production
              environment
            </li>
            <li>
              Configured Amazon DynamoDB, AWS Lambda, and Amazon S3 to transform
              raw data from traffic sources into business reports and queryable
              data, enhancing data accessibility and facilitating data-driven
              decision-making
            </li>
            <li>
              Implemented Python scripts for ETL operations in AWS Glue,
              enabling movement of data from traffic sources into target systems
              such as Amazon S3 and Amazon Redshift
            </li>
          </ul>
          <strong style={{ color: "white" }}>
            <u>Team 2: Web Team (Jan 2022 - Aug 2022)</u>
          </strong>
          <br></br>
          <div style={{ color: "white" }}>
            <strong>Stack: </strong>
            Java, JavaServer Faces (JSF), PrimeFaces, HTML, CSS, JavaScript,
            Eclipse, Postman, Salesforce, SVN
          </div>

          <ul className="tasks-list">
            <li>
              Designed and implemented a Java form integrated with Salesforce,
              streamlining the business teams' response to customer requests
              thereby improving response times by 72%
            </li>
            <li>
              Coded payment limiting logic within 407 ETR's codebase, resulting
              in 90% reduction of fees associated with large credit card
              payments
            </li>
            <li>
              Collaborated on the design-end with UI/UX designers to enhance the
              website’s usability and experience and regularly deployed
              enhancements developed in JavaScript, HTML5 and CSS3 through
              Jenkins
            </li>
          </ul>
        </div>
        {data.map((item, i) => {
          if (i > Four07index) {
            return <ExperienceCard item={item} />;
          }
        })}
      </div>
    </div>
  );
}

export default Experience;
