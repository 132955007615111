import React from "react";
import "./mobile.css";
import { AiOutlineCloseCircle, AiOutlineLaptop } from "react-icons/ai";
import { BsFillPencilFill, BsBriefcase, BsPerson } from "react-icons/bs";

function MobileHeader({ isOpen, setIsOpen }) {
  return (
    <div className="mobile">
      <div className="close-icon" onClick={() => setIsOpen(!isOpen)}>
        <AiOutlineCloseCircle />
      </div>
      <div className="mobile-options">
        <div className="mobile-option">
          <a onClick={() => setIsOpen(!isOpen)} href="#experience">
            <BsBriefcase className="option-icon" /> Experience
          </a>
        </div>
        <div className="mobile-option">
          <a onClick={() => setIsOpen(!isOpen)} href="#projects">
            <BsFillPencilFill className="option-icon" />
            Projects
          </a>
        </div>

        <div className="mobile-option">
          <a onClick={() => setIsOpen(!isOpen)} href="#skills">
            <AiOutlineLaptop className="option-icon" /> Skills
          </a>
        </div>
        <div className="mobile-option">
          <a onClick={() => setIsOpen(!isOpen)} href="#contact">
            <BsPerson className="option-icon" /> Contact
          </a>
        </div>
      </div>
    </div>
  );
}

export default MobileHeader;
