import React from "react";
import "./web.css";
import { BsFillPencilFill, BsBriefcase, BsPerson } from "react-icons/bs";
import { AiOutlineLaptop } from "react-icons/ai";

function WebHeader() {
  return (
    <div className="web">
      <div className="web-option">
        <a href="#experience">
          <BsBriefcase className="option-icon" /> Experience
        </a>
      </div>
      <div className="web-option">
        <a href="#projects">
          <BsFillPencilFill className="option-icon" />
          Projects
        </a>
      </div>
      <div className="web-option">
        <a href="#skills">
          <AiOutlineLaptop className="option-icon" /> Skills
        </a>
      </div>
      <div className="web-option">
        <a href="#contact">
          <BsPerson className="option-icon" /> Contact
        </a>
      </div>
    </div>
  );
}

export default WebHeader;
