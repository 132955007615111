import React from "react";
import "./projectcardcopy.css";
import { BsGlobe, BsGithub } from "react-icons/bs";

function ProjectCard({ project }) {
  return (
    <div className="project-card">
      <div className="project-card-content">
        <img src={project.image} alt="" className="project-photo" />
        <div className="project-title">{project.title}</div>
        <div className="project-tags">
          {project.tags.map((tag) => {
            return <div className="tag">{tag}</div>;
          })}
        </div>
        <div className="project-links">
          {project.demo && (
            <a
              className="project-link"
              href={project.demo}
              target="_blank"
              rel="noreferrer"
            >
              <div className="link-button">
                <BsGlobe className="external-link" /> Demo
              </div>
            </a>
          )}
          {project.readmore && (
            <a
              className="project-link"
              href={project.readmore}
              target="_blank"
              rel="noreferrer"
            >
              <div className="link-button">
                <BsGlobe className="external-link" /> Read More
              </div>
            </a>
          )}
          {project.github && (
            <a
              className="project-link"
              href={project.github}
              target="_blank"
              rel="noreferrer"
            >
              <div className="link-button">
                <BsGithub className="external-link" /> Github
              </div>
            </a>
          )}
        </div>
        <div className="project-description">{project.about}</div>
        <br></br>
        <div>
          {project.tasks && <strong>What I did: </strong>}
          <ul className="project-description">
            {project.tasks &&
              project.tasks.map((task) => {
                return <li>{task}</li>;
              })}
          </ul>
        </div>
      </div>

      {/* <div className="project-info">
        <div className="project-title-date">
          <div className="project-title">{project.title}</div>
          <div className="project-dates">{project.dates}</div>
        </div>

        <div className="project-links">
          {project.demo && (
            <a
              className="project-link"
              href={project.demo}
              target="_blank"
              rel="noreferrer"
            >
              <div className="link-button">
                <BsGlobe className="external-link" /> Demo
              </div>
            </a>
          )}
          {project.github && (
            <a
              className="project-link"
              href={project.github}
              target="_blank"
              rel="noreferrer"
            >
              <div className="link-button">
                <BsGithub className="external-link" /> Github
              </div>
            </a>
          )}
        </div>
        <div className="project-tags">
          {project.tags.map((tag) => {
            return <div className="tag">{tag}</div>;
          })}
        </div>
        <p className="project-info">
          <strong>Description: </strong>
          {project.about}
        </p>
        <br></br>
        {project.tasks && <strong>What I did: </strong>}
        <ul>
          {project.tasks &&
            project.tasks.map((task) => {
              return <li>{task}</li>;
            })}
        </ul>
      </div>
      <img src={project.image} alt="" className="project-photo" /> */}
    </div>
  );
}

export default ProjectCard;
