export const SkillsData = [
  {
    name: "JavaScript",
    icon: <i class="devicon-javascript-plain colored"></i>,
  },
  {
    name: "TypeScript",
    icon: <i class="devicon-typescript-plain colored"></i>,
  },
  {
    name: "Java",
    icon: <i class="devicon-java-plain-wordmark colored"></i>,
  },
  {
    name: "C++",
    icon: <i class="devicon-cplusplus-plain colored"></i>,
  },
  {
    name: "Python",
    icon: <i class="devicon-python-plain colored"></i>,
  },
  {
    name: "HTML5",
    icon: <i class="devicon-html5-plain colored"></i>,
  },
  {
    name: "CSS3",
    icon: <i class="devicon-css3-plain colored"></i>,
  },
  {
    name: "Sass",
    icon: <i class="devicon-sass-original colored"></i>,
  },
  {
    name: "ReactJS",
    icon: <i class="devicon-react-original colored"></i>,
  },
  {
    name: "Redux",
    icon: <i class="devicon-redux-original colored"></i>,
  },
  {
    name: "NodeJS",
    icon: <i class="devicon-nodejs-plain colored"></i>,
  },

  {
    name: "Express",
    icon: <i class="devicon-express-original colored"></i>,
  },

  {
    name: "MongoDB",
    icon: <i class="devicon-mongodb-plain colored"></i>,
  },
  {
    name: "Firebase",
    icon: <i class="devicon-firebase-plain-wordmark colored"></i>,
  },
  {
    name: "Git",
    icon: <i class="devicon-git-plain colored"></i>,
  },
  { name: "Jenkins", icon: <i class="devicon-jenkins-plain colored"></i> },
  {
    name: "Figma",
    icon: <i class="devicon-figma-plain colored"></i>,
  },
];
