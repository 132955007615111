import React from "react";
import "./experienceCard.css";

function ExperienceCard({ item }) {
  return (
    <div>
      <div className="work-card">
        <img src={item.logo} className="work-logo" alt="" />
        <div className="work-info">
          <div className="company-name">{item.company}</div>
          <br />
          <div className="company-job">
            <strong>{item.designation}</strong>
          </div>
          <div className="work-dates">
            {item.dateJoining} - {item.dateEnd}
          </div>
        </div>
      </div>
      <div style={{ color: "white" }}>
        <strong>Stack: </strong>
        {item.stack}
      </div>
      <ul className="tasks-list">
        {item.tasks &&
          item.tasks.map((task) => {
            return <li>{task}</li>;
          })}
      </ul>
    </div>
  );
}

export default ExperienceCard;
