export const SocialData = [
  {
    platform: "GitHub",
    icon: require("../assets/icons/github.png"),
    link: "https://github.com/nurinfazil",
  },
  {
    platform: "Linkedin",
    icon: require("../assets/icons/linkedin.png"),
    link: "https://www.linkedin.com/in/nurinfazil/",
  },
];
