import React from "react";
import { ProjectData } from "../../../data/projects";
import Seperator from "../../common/seperator/Seperator";
import ProjectCard from "./ProjectCard";
import "./projects.css";

function Projects() {
  const data = ProjectData;
  return (
    <div className="projects">
      <Seperator />
      <label className="section-title" style={{ color: "white" }}>
        Projects
      </label>
      {/* <div className="projects-container">
        {data.map((project) => {
          return <ProjectCard project={project} />;
        })}
      </div> */}
      <div className="projects-container1">
        {data.map((project) => {
          return <ProjectCard project={project} />;
        })}
      </div>
    </div>
  );
}

export default Projects;
