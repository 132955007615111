import React from "react";
import SocialContact from "../../common/social-contact/SocialContact";
import "./about.css";

function About() {
  return (
    <div className="about">
      <div className="about-top">
        <div className="about-info">
          👋 Hey, my name is <span className="info-name">Nurin Fazil.</span>
          <br></br>
          <br></br> I am a passionate web developer striving to build great
          tools. I am curious, hard-working and ready for a challenge.
          <br></br>
          <br></br>I have experience with front-end and back-end development
          with a dash of design experience. If you would like to know more, keep
          scrolling!
        </div>
      </div>
      <br></br>
      <br></br>
      <SocialContact />
    </div>
  );
}

export default About;
