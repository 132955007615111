import React from "react";
import Seperator from "../../common/seperator/Seperator";
import "./contact.css";
import SocialContact from "../../common/social-contact/SocialContact";
import { AiOutlineCloudDownload } from "react-icons/ai";

function Contact() {
  return (
    <div className="contact">
      <Seperator />
      <div className="section-title" style={{ color: "white" }}>
        Contact
      </div>
      <div className="contact-container">
        <div className="contact-left">
          <p>Want to get in touch? Connect with me on any of these platforms</p>
          <SocialContact />
        </div>
        <div className="download">
          <a
            rel="noreferrer"
            target="_blank"
            href={require("../../../assets/NurinFazil.pdf")}
          >
            <AiOutlineCloudDownload className="download-icon" />
            View Resume
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contact;
