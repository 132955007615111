import React from "react";
import "./skills.css";
import Seperator from "../../common/seperator/Seperator";
import { SkillsData } from "../../../data/skills";
import SkillCard from "./SkillCard";

const data = SkillsData;

function Skills() {
  return (
    <div className="skills">
      <Seperator />
      <div className="section-title" style={{ color: "white" }}>
        Skills
      </div>
      <div className="skills-container">
        {data.map((skill) => {
          return <SkillCard skill={skill} />;
        })}
      </div>
    </div>
  );
}

export default Skills;
